import React from 'react';

import { gsap } from 'gsap';
import TextHeader from '../components/TextHeader';
import ContactBanner from '../components/ContactBanner';
import PartnersBanner from '../components/PartnersBanner';
import {Helmet} from "react-helmet";

const Partners = () => {



   return (
      <div>
         <Helmet>
            <meta name="description" content="Samenwerking tussen Palm CV en zijn partners."></meta>
            <meta name="keywords" content="Vaillant, Viessmann, Henrad, Zehnder"></meta>
            <title>Palm CV - Partners</title>
         </Helmet>

         <TextHeader
            headTitle="Kwaliteit staat bij ons voorop."
            title="Onze partners"
            text="Wij staan klaar om u te helpen."
         />
         
         <PartnersBanner />

         <ContactBanner
            headTitle="Heeft u vragen?"
            title="Twijfel niet om ons te contacteren"
            link="/contacteer-ons"
            buttonText="Contacteer ons"
         />
      </div>
   )
}

export default Partners;