import React, { useRef, useEffect } from 'react';

const TextHeader = (props) => {

   return (
      <div className="container-fluid accent-dark">
         <div className="row title-header">
            <div className="container">
               <div className="col-md-12 no-header">
               <h1 className="accent">
                  {props.title}
               </h1>
               <p className="cw">{props.text}</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default TextHeader;