import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

const OnderhoudBanner = (props) => {



   return (
      <div className="container-fluid bubbleBg position-relative" id="onderhoud">

         <div className="accentBg bottom opacity-low"></div>

         <div className="row">
            <div className="container">
               <div className="row">

                  <div className="col-lg-5 col-md-6 col-sm-12 p-5">
                     <img src={require("../images/IMG-20200812-WA0001.jpg")} alt="Palm CV onderhoud verwarmingsinstallatie" className="offset" />
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-12 my-auto">
                     <h1>{props.title}</h1>
                     <p>Wist je dat het onderhoud van een gasketel elke 2 jaar en mazoutketel jaarlijks wettelijk verplicht is?</p>
                     <Link to={props.buttonLink} className={props.buttonClass + " button accent-button"}>
                        {props.button}
                     </Link>
                  </div>

               </div>
            </div>
         </div>
      </div>
   )
}

export default OnderhoudBanner;