import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes, Navigation, Footer } from "./components";

import './App.scss';
import News from './components/news';
import ScrollToTop from './components/ScollToTop';
// import Routes from './components/Routes';

class App extends Component{

   render(){

      return(
         <div className="App">
            <Router>
               <ScrollToTop />
               <Navigation />
               <Routes />
               <Footer />
            </Router>

         </div>
      )
   }
}

export default App;
