import React, { Component } from "react";
import { Route, Switch, useLocation } from 'react-router-dom';

import {
   Home,
   Over,
   Partners,
   Realisaties,
   Contact
} from "./";

// import { gsap } from 'gsap';



const Routes = () => {

   const routes = [
      { path: "/", name: "Home", Component: Home },
      { path: "/over-ons", name: "Over ons", Component: Over },
      { path: "/partners", name: "Partners", Component: Partners },
      { path: "/realisaties", name: "Realisaties", Component: Realisaties },
      { path: "/contacteer-ons", name: "Contacteer ons", Component: Contact },
   ]

   const location = useLocation()

   return (
         <Switch>
            {routes.map(({ path, Component, name }) => (
               <Route key={name} path={path} exact>
                  <div className="page">
                     <Component />
                  </div>

               </Route>
            ))}
         </Switch>
   );

}

export default Routes;