import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel'

const Slider = () => {

   const [index, setIndex] = useState(0);

   const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
   };

   return (

      <Carousel activeIndex={index} onSelect={handleSelect}>
         <Carousel.Item>
            <img
               className="d-block w-100"
               src={require('../images/carousel/Badkamer 4.png')}
               alt="Badkamer"
            />
            
         </Carousel.Item>
         <Carousel.Item>
            <img
               className="d-block w-100"
               src={require('../images/carousel/airco SK.png')}
               alt="airco"
            />

         </Carousel.Item>
         <Carousel.Item>
            <img
               className="d-block w-100"
               src={require('../images/carousel/IMG-20200812-WA0000.png')}
               alt="Palm CV"
            />

         </Carousel.Item>
         <Carousel.Item>
            <img
               className="d-block w-100"
               src={require('../images/carousel/zonnepanelen 1.jpg')}
               alt="Zonnepanelen"
            />

         </Carousel.Item>
         <Carousel.Item>
            <img
               className="d-block w-100"
               src={require('../images/carousel/20191204_130318.png')}
               alt="slide 5"
            />

         </Carousel.Item>
      </Carousel>
   );
}

export default Slider;
