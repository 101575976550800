import React from 'react';


const Gegevens = () => {

   return(
      <>
         <li>BTW 0567 894 418 </li>
         <li>Bank Van Breda IBAN BE23645102224291</li>
         <li>BNP Paribas Fortis IBAN BE09001880990957</li>
      </>
   )
}

export default Gegevens;