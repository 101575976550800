import React from "react";

import facebook from '../images/icons/facebook.svg';
import instagram from '../images/icons/instagram.svg';
import whatsapp from '../images/icons/whatsapp.svg';

const Social = () => {

    return (
        <ul className="social list-unstyled">
            <li className="d-inline-block pr-3"><a href="https://www.facebook.com/pages/category/Plumbing-Service/Palm-cv-bv-103919891250331/"><img src={facebook} alt="Facebook Palm CV" /></a></li>
            <li className="d-inline-block pr-3">
                <a href="https://www.instagram.com/palmcv.bv/">
                    <img src={instagram} alt="Instagram Palm CV" />
                </a>
            </li>
            <li className="d-inline-block">
               <a href="https://api.whatsapp.com/send?phone=32468544313">
                  <img src={whatsapp} alt="whatsapp Palm CV"/>
               </a>
            </li>
        </ul>
    )

}

export default Social;
