import React, { useState, useEffect } from 'react';
import TextHeader from '../components/TextHeader';
import ContactBanner from '../components/ContactBanner';
import Images from '../components/Images';
import { Helmet } from "react-helmet";
import PartnersBanner from '../components/PartnersBanner';
// import Vakgebied from '../components/vakgebied';
// import { Link } from 'react-router-dom';



const Realisaties = (props) => {

   const toggleHover = () => {

   }

   return (
      <div id="contact">

         <Helmet>
            <meta name="description" content="Bij Palm CV zijn we trots op wat we realiseren."></meta>
            <title>Palm CV - Realisaties</title>
         </Helmet>

         <TextHeader
            headTitle="Trots op wat we vervaardigen."
            title="Onze realisaties"
            text="Enkele voorbeelden van onze realisaties."
         />

         <div className="container realisaties">

            <div className="row" id="intro">
               <div className="col-md-12 col-sm-12 mb-5">
                  <h2>Vakmanschap</h2>
                  <p>Peter Verwaest uit Retie is meer dan 10 jaar een garantie voor de beste prijs-kwaliteit als het gaat om de installatie én het onderhoud van uw Centrale Verwarming, Warmtepomp, Airco en al uw Sanitair. Palm CV is een lokaal sterke partner voor jullie nieuwbouwwoning of renovatie. We hebben een professioneel team die van voorstel tot installatie en onderhoud ter beschikking staat. </p>

               </div>
               <div className="col-md-5 col-sm-12 ">
                  {/* <Vakgebied /> */}
               </div>
            </div>

            <div className="row">
               {Images.map(({ image, id, categorie }) => (
                  <div className="col-xl-4 col-lg-6 col-sm-12" key={id}>
                     <div
                        className="realisatie"
                        style={{ backgroundImage: 'url(' + image + ')' }}
                        onMouseEnter={toggleHover}
                     >
                        <div className="realisatie-info">
                           <div className="realisatie-info-inner">
                              <h2>{categorie}</h2>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <PartnersBanner />

         <ContactBanner
            headTitle="Heeft u interesse?"
            title="Heeft u vragen of wenst u een gratis offerte?"
            link="/contacteer-ons"
            buttonText="Contacteer ons"
         />

      </div>
   )
}

export default Realisaties;