import React from 'react';
import {Link} from 'react-router-dom';

const ContactBanner = (props) => {

   return (
      <div className="container-fluid contact-banner-small">
         <div className="row h-100">
            <div className="col-md-12 my-auto text-center">
               <span>{props.headTitle}</span>
               <h2 className="mb-5">{props.title}</h2>
               <Link className="button accent-button dark with-arrow" to={props.link}>
                  <img src={require("../images/icons/arrow.svg")} alt="" />
                  {props.buttonText}
                  <div className="buttonSpan"></div>
               </Link>
            </div>
         </div>
      </div>
   );
};

export default ContactBanner;