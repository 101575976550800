import React from 'react';
import { Link } from 'react-router-dom';
import Hours from '../components/OpeningHours';
import Social from '../components/Social';
import Address from '../components/Address';
import Pdf from '../documents/verkoopsvoorwaarden.pdf';
const Footer = () => {

   return (

      <footer>
         <div className="accentBg opacity-low top"></div>

         <div className="container">
            <div className="row">

               <div className="col-md-12">
                  <img src={require('../images/logo_original.png')} alt="" className="logo-small" />
               </div>

               <div className="col-lg-4 col-md-6 col-sm-12">
                  <Address />
                  <Social />
               </div>
               <div className="col-lg-4 col-md-6 col-sm-12">
                  <Hours />
               </div>
               <div className="col-lg-4 col-md-6 col-sm-12">
                  <ul className="list-unstyled">
                     <li>
                        <Link to="/">Home</Link>
                     </li>
                     <li>
                        <Link to="/over-ons">Over ons</Link>
                     </li>
                     <li>
                        <Link to="/partners">Partners</Link>
                     </li>
                     <li>
                        <Link to="/realisaties">Onze realisaties</Link>
                     </li>
                     <li>
                        <a href="https://www.premiezoeker.be/">Premiezoeker</a>
                     </li>
                     <li>
                        <a href={Pdf}>verkoopsvoorwaarden</a>
                     </li>
                  </ul>
                  <Link to="contacteer-ons" className="button accent-button accent-second">
                  Contacteer ons 
                     </Link>
               </div>
            </div>
         </div>

         <div className="copyright">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <a href="">&copy; CMCweb {new Date().getFullYear()}</a>
                  </div>
               </div>
            </div>
         </div>
         
      </footer>

   )
}

export default Footer;