import React from 'react';
import Header from '../components/header';
import Vakgebied from '../components/vakgebied';
import OnderhoudBanner from '../components/onderhoudBanner';
import RealisatiesBanner from '../components/realisatiesBanner';
import Slider from '../components/Slider';
import { Helmet } from "react-helmet";
import { HashLink as Link } from 'react-router-hash-link';
//aos for scroll animation?


const Home = () => {

   return (


      <div>

         <Helmet>
            <meta name="keywords" content="Centrale Verwarming, Warmtepomp, Airco, Sanitair, Retie, CV"></meta>
            <meta name="description" content="Peter Verwaest uit Retie is meer dan 10 jaar een garantie voor de beste prijs-kwaliteit als het gaat om de installatie én het onderhoud van uw Centrale Verwarming, Warmtepomp, Airco en al uw Sanitair."></meta>
            <title>Palm CV - Vakmanschap</title>
         </Helmet>

         <div className="container-fluid np">
            <div className="main-header">
               <div className="header-accent">
                  <div className="container">
                     <div className="header-intro">
                        <div className="col-md-12 col-sm-12">
                           <ul className="list-unstyled subnav">
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_verwarming">Verwarming</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_sanitair">Sanitair</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_renovatie">Badkamerrenovaties</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_airco">Airco</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_ventilatie">Ventilatie</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_zonnepanelen">Zonnepanelen</Link></li>
                              <li className="d-inline pr-3"><Link smooth to="over-ons#s_onderhoud">Onderhoud</Link></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="slider-container container-fluid position-relative">
                  <Slider />
                  <div className="col-md-12 carousel-banner">
                     Peter en Ann Verwaest en hun team staan reeds meer dan 10 jaar voor je klaar met hun vakkennis en professioneel advies. <br></br>
                     Palm CV   Wenen 45, Retie | Tel 014 70 72 62
                  </div>
               </div>
            </div>

            <div class="box">
               <span></span>
               <span></span>
               <span></span>
            </div>

         </div>

         <div className="container mb-offset">
            <div className="row">
               <div className="col-lg-12">
                  <h2 class="mb-3">Wie is Palm CV</h2>
                  <p className='mt-3'>Peter Verwaest uit Retie is meer dan 10 jaar een garantie voor de beste prijs-kwaliteit als het gaat om de installatie én het onderhoud van uw Centrale Verwarming, Warmtepomp, Airco en al uw Sanitair. Palm CV is een lokaal sterke partner voor jullie nieuwbouwwoning of renovatie. We hebben een professioneel team die van voorstel tot installatie en onderhoud ter beschikking staat. Als bedrijf hebben we zowel zakelijke als privatieve projectervaring. We werken samen met architecten, studiebureaus en leveranciers, voor zowel particuliere klanten als scholen en bedrijven. Onder het motto 'afspraak is afspraak' leveren Peter en zijn medewerkers altijd een uitstekende service.</p>
                  <p className="mt-5">
                     <Link to="/over-ons" className="button accent-button accent-second">
                        Meer over ons
                     </Link>
                  </p>
               </div>
            </div>
         </div>

         {/* <div className="container container-half-screen mt-5" id="intro">
            <div className="row">
               <div className="col-md-7 col-sm-12 ">
                  <h2>Vakmaschap</h2>
                  <p>Peter Verwaest uit Retie is meer dan 10 jaar een garantie voor de beste prijs-kwaliteit als het gaat om de installatie én het onderhoud van uw Centrale Verwarming, Warmtepomp, Airco en al uw Sanitair. Palm CV is een lokaal sterke partner voor jullie nieuwbouwwoning of renovatie. We hebben een professioneel team die van voorstel tot installatie en onderhoud ter beschikking staat. </p>
                  <Link to="/contacteer-ons" className="button accent-button accent-second">
                     Meer over ons
                  </Link>
               </div>
               <div className="col-md-5 col-sm-12 ">
                  <Vakgebied />
               </div>
            </div>
         </div> */}


         <OnderhoudBanner
            title="Onderhoud van verwarmingsinstallatie"
            button="Maak een afspraak"
            buttonLink="/contacteer-ons"
            buttonClass="dark"
         />


         <RealisatiesBanner
            button="Onze realisaties"
            buttonClass="dark with-arrow big"
            buttonLink="/realisaties"
            backgroundClass="accentBg opacity-low bottom"
            containerClass="padding-105"
         />
      </div>
   )
}

export default Home;