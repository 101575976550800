import React, { useState, useEffect } from 'react';
import {useForm} from "react-hook-form";
import axios from '../axios';
import Privacyverklaring from '../documents/Privacyverklaring.pdf';
import Pdf from '../documents/verkoopsvoorwaarden.pdf';

const ContactForm = (props) => {

   const {register, handleSubmit, errors } = useForm();


   const onSubmit = (data, e) => {
      axios({
         method: "post",
         url: "https://palmcv.be/api/send.php",
         // url: "/api/send.php",
         headers: { "content-type": "application/json" },
         data: data,
      })
      .then(function(response){
         e.target.reset();
         document.querySelector('.message').innerHTML = "Bedankt voor uw bericht. We proberen u zo snel mogelijk verder te helpen."
      })
      .catch(function (response) {
         console.log(response);
      })

   }

   return (
      
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
         <div className="row form-group">
            <Input 
               type="text" 
               label="Voornaam"
               name="firstname"
               col="col-md-6 col-sm-12"
               // onChange={handleChange}
               register={register({required: true})}
               className={errors.firstname ? 'inputError' : ''}
            />
            <Input 
               type="text" 
               label="Achternaam"
               name="lastname"
               col="col-md-6 col-sm-12"
               // onChange={handleChange}
               register={register({required: true})}
               className={errors.lastname ? 'inputError' : ''}

            />
         </div>

         <div className="row form-group">
            <Input 
               type="mail" 
               label="E-mailadres"
               name="mail"
               col="col-md-6 col-sm-12"
               // onChange={handleChange}
               register={register({required: true})}
               className={errors.mail ? 'inputError' : ''}
            />
            <Input 
               type="phone" 
               label="Telefoonnummer"
               name="phone"
               col="col-md-6 col-sm-12"
               // onChange={handleChange}
               register={register({required: false})}
            />
         </div>
      
         <div className="row form-group">
            <Dropdown 
               type="type" 
               label="Onderwerp"

               name="subject"
               col="col-md-12"
               // onChange={handleChange}
               register={register({required: true})}
               className={errors.subject ? 'inputError' : ''}

               items={[
                     "Onderhoud verwarmingsinstallatie",
                     "Verwarming",
                     "Sanitair",
                     "Airco",
                     "Koeling",
                     "Badkamerrenovaties",
                     "Ventilatie",
                     "Andere"
                  ]
               }
            />
         </div>

         <div className="row form-group">
            <div className="col-md-12">
               <label htmlFor="message">Bericht</label>
               <textarea 
                  name="message" 
                  id="message" 
                  cols="30" 
                  rows="6" 
                  ref={register({required: true})}
                  className={errors.message ? 'inputError form-control' : 'form-control'} 
               />
            </div>
         </div>
         
         <button className="button accent-button accent-second">
            Verzenden
         </button>
         <br/>
         <span className="smaller">Door te verzenden gaat u akkoord met onze <a href={Pdf}>algemene voorwaarden</a> en onze <a href={Privacyverklaring}>privacyverklaring</a>.</span>
         
         <div className="message">

         </div>
      </form>
   );
};

const Input = (props) =>{
   return (
      <div className={props.col}>
         <label htmlFor={props.name}>{props.label}</label>
         <input 
            type={props.type} 
            className={props.className + " form-control"} 
            name={props.name} id={props.name} 
            onChange={props.onChange}
            ref={props.register}
         />
      </div>
   )
}

const Dropdown = (props) =>{
   return(
      <div className={props.col}>
         <label htmlFor={props.name}>{props.label}</label>
         <select 
            ref={props.register} 
            name={props.name} 
            id={props.name} 
            className={props.className + " form-control"} 
            onChange={props.onChange
         }>
            {props.items.map((value, key)=>(
               <option key={key} value={value}>{value}</option>
            ))}
         </select>
      </div>
   )
};

export default ContactForm;