import React from "react";

const Hours = () => {

   return (
      <ul className="list-unstyled">
         <li>Maandag - Vrijdag</li>
         <li>08u00 - 12u00 | 13u30 - 17u30</li>
         <li className="spacer"></li>
         <li>Op afspraak kunnen we de nodige tijd voor u reserveren.</li>
      </ul>
   )
}

export default Hours;