import React from 'react';

const Address = () => {

   const mail = "info@palmcv.be";
   
   return(
      <ul className="list-unstyled">
         <li>
            Wenen 45
         </li>
         <li>
            2470 Retie
         </li>
         <li>
            014 70 72 62
         </li>
         <li>
            <a href={"mailto:"+ mail}>{mail}</a>
         </li>
      </ul>
   )
}

export default Address;