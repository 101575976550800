import React, {Component } from 'react';
// import Axios from 'axios';
import axios from '../axios';

class News extends Component{

   constructor(props){
      super(props);
      this.state = {
         News: []
      };
   }

   getUsersData() {
      axios
         .get(`/`, {})
         .then(res => {
            const data = res.data
            this.setState({ news: data });
            console.log(data)
         })
         .catch((error) => {
            console.log(error)
         })

   }
   componentDidMount(){
      this.getUsersData()
   }

   render(){

      return(
         <div>
            {this.state.news}
         </div>
      )
   }
}

export default News;