import React, { Component } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

class Navigation extends Component {

   state = {
      isTop: true,
      navClass: "dark"
   };

   componentDidMount() {
      document.addEventListener('scroll', () => {
         const isTop = window.scrollY < 100;
         if (isTop !== this.state.isTop) {
            this.setState({ isTop })
         }
      });
   }

   toggleNav = (e) => {
      let navigation = document.getElementById('navigation');
      let toggler = document.getElementById('navToggler');
      navigation.classList.toggle("active");
      toggler.classList.toggle("active");
   }


   render() {

      return (
         <div className={this.state.isTop ? 'top' : 'scrolled'} >
            <nav className={this.state.navClass + " navbar fixed-top"} >
               <div className="navToggler" id="navToggler" onClick={this.toggleNav} >
                  <span className="first"></span>
                  <span className="second"></span>
                  <span className="third"></span>
               </div>
               <div className="navigation" id="navigation">

                  <div className="logo left">
                     <NavLink to="/"><img src={require('../images/logo2.png')} alt="Palm CV" /></NavLink>
                  </div>

                  <div className="container position-relative">

                     <ul className="navbarList position-relative">
                        <li className="navItem" >
                           <NavLink
                              onClick={this.toggleNav}
                              exact={true}
                              className="nav-link"
                              activeClassName="current"
                              to="/"> Home
                           </NavLink>
                        </li>
                        <li className="navItem" >
                           <NavLink
                              onClick={this.toggleNav}
                              exact={true}
                              className="nav-link"
                              activeClassName="current"
                              to="over-ons">
                              Over ons
                              </NavLink>
                        </li>
                        <li className="navItem" >
                           <NavLink
                              onClick={this.toggleNav}
                              exact={true}
                              className="nav-link"
                              activeClassName="current"
                              to="partners">
                              Partners
                           </NavLink>
                        </li>
                        <li className="navItem" >
                           <NavLink exact={true}
                              onClick={this.toggleNav}
                              className="nav-link"
                              activeClassName="current"
                              to="realisaties">
                              Realisaties
                           </NavLink>
                        </li>
                        <li className="navItem" >
                           <NavLink
                              onClick={this.toggleNav}
                              exact={true}
                              className="nav-link"
                              activeClassName="current"
                              to="contacteer-ons">
                              Contacteer ons
                           </NavLink>
                        </li>
                        <li className="navItem">
                           <a href=''
                              className="nav-link"
                              href="https://www.premiezoeker.be/"
                              target="_BLANK"
                           >
                              Premiezoeker
                           </a>
                        </li>
                        <li className="navItem">
                           <a href=''
                              className="nav-link"
                              href="https://www.energiesparen.be/"
                              target="_BLANK"
                           >
                              Energiesparen
                           </a>
                        </li>
                     </ul>



                  </div>
                  <div className="logo">
                     <NavLink to="/"><img src={require('../images/logo_w.png')} alt="" /></NavLink>
                  </div>
               </div>
            </nav>
         </div>
      )
   }

}

export default Navigation;