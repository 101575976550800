
const Images = [
   { id: 1, image: require("../images/realisaties/r_1.jpeg"), categorie: "Badkamer" },
   { id: 2, image: require("../images/realisaties/r_2.jpeg"), categorie: "Badkamer" },
   { id: 3, image: require("../images/realisaties/r_3.jpeg"), categorie: "Badkamer" },
   { id: 4, image: require("../images/realisaties/r_4.jpeg"), categorie: "Badkamer" },
   { id: 5, image: require("../images/realisaties/r_5.jpeg"), categorie: "Badkamer" },
   { id: 6, image: require("../images/realisaties/r_6.jpeg"), categorie: "Badkamer" },
   { id: 7, image: require("../images/realisaties/r_7.jpeg"), categorie: "Verwarming" },
   { id: 8, image: require("../images/realisaties/r_8_thumb.jpg"), categorie: "Airco" },
   { id: 9, image: require("../images/realisaties/r_9.jpg"), categorie: "Ketel met zonneboiler" },
   { id: 10, image: require("../images/realisaties/r_10.jpg"), categorie: "Ketel met zonneboiler" },
   { id: 11, image: require("../images/realisaties/r_11.jpg"), categorie: "Ventilatie" },
   { id: 12, image: require("../images/realisaties/r_12.jpg"), categorie: "Centrale stookplaats" },
   { id: 13, image: require("../images/realisaties/r_13.jpg"), categorie: "Vloerverwarming" },
   { id: 14, image: require("../images/realisaties/r_14.jpg"), categorie: "Kelderinstallatie" },
]


export default Images;