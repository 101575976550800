import React from 'react';
import RealisatiesBanner from '../components/realisatiesBanner';
import ContactBanner from '../components/ContactBanner';
import TextHeader from '../components/TextHeader';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


const Over = () => {

   const array = [
      require("../images/realisaties/r_3.jpeg"),
      require("../images/realisaties/r_2.jpeg"),
      // require("../images/realisatie2.png")
   ]

   return (
      <div>

         <Helmet>
            <meta name="description" content="Palm CV is een lokaal sterke partner voor je nieuwbouwwoning of renovatie."></meta>
            <title>Palm CV - Over ons</title>
         </Helmet>


         <TextHeader
            title="Over Palm CV"
            text="Palm CV is een lokaal sterke partner voor je nieuwbouwwoning of renovatie."
         />

         <div className="container mt-5" id="about">

            <div className="row mb-5">
               <div className="col-md-12 my-auto">
                  <h1>Wie zijn wij</h1>
                  <p>
                     Peter Verwaest uit Retie is meer dan 10 jaar een garantie voor de beste prijs-kwaliteit als het gaat om de installatie én het onderhoud van uw Centrale Verwarming, Zonnepanelen, Zonneboiler, Warmtepomp, Airco en al uw Sanitair.
                     Palm CV is een lokaal sterke partner voor jullie nieuwbouwwoning of renovatie. We hebben een professioneel team die van voorstel tot installatie en onderhoud ter beschikking staat.
                     Als bedrijf hebben we zowel zakelijke als privatieve projectervaring. We werken samen met architecten, studiebureaus en leveranciers, voor zowel particuliere klanten als scholen en bedrijven. Onder het motto 'afspraak is afspraak' leveren Peter en zijn medewerkers altijd een uitstekende service.
                  </p>

                  <p>
                     Als meewerkend zaakvoerder is Peter Verwaest op elke werf aanwezig, de garantie voor een perfecte afwerking en oplevering.
                     Focus op regio Kempen, geen verloren reistijden door files en onhaalbare afspraken.
                     Palm CV heeft een uitgebreid netwerk van contacten en leveranciers. Samen met hun sterk team en een slanke organisatie staat dit garant voor competitieve prijzen, kwaliteit en betrouwbare service.
                  </p>
               </div>

            </div>

            <div className="row mb-5" id="s_verwarming">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Verwarming</h1>
                  <p>
                     U bent  in jou buurt op zoek naar een erkend installateur voor het plaatsen of het vervangen van centrale verwarming of boiler?  Palm CV  heeft een jarenlange ervaring, u kan u rekenen op een perfecte installatie en service.</p>
                  <p>Weet u wat u wenst of zoekt u advies welke oplossing in uw woning  het  beste rendement geeft?  Gas, mazout , radiatoren, convectoren, boiler, geiser off vloerverwarming?</p>
                  <p>Bij bv nieuwbouw is vloerverwarming kiezen voor een aangename en comfortabele manier van verwarmen . Vloerverwarming is geschikt voor bijna alle typen vloeren en is bovendien een zeer efficiênte en kostbesparende verwarming.</p>
                  <p>Met een alternatieve verwarmingssysteem zoals een warmtepomp kiest u voor een duurzame en zuinige oplossing voor de verwarming van uw woning of zwembad.</p>
                  <p>Bovendien behalen de nieuwste warmtepompen een zeer hoog rendement, waardoor de investering zeker wordt terugverdiend</p>
                  <p>Bent u op zoek naar een duurzame en energiebesparende manier om warm water op te wekken dan overweegt u best de installatie van een zonneboiler.</p >
                  <p>Een zonneboiler functioneert samen met de zonnecollectoren die wij op het dak van uw woning plaatsen.De zonnecollector vangt het zonlicht op en zet het om in warmte.Het water in het voorraadvat wordt op die manier voorverwarmd voor het naar uw verwarmingsketel doorstroomt en kan u tot 65 % energie besparen op de productie van uw warm water.</p >
                  <p>Bent u van plan om een elektrische boiler te laten plaatsen of wilt u uw bestaande warmwaterboiler laten herstellen of onderhouden?</p >
                  <Link to="/contacteer-ons" className="button accent-button accent-second mb-5 ml-3">
                     Vraag nu volledig vrijblijvend uw gratis offerte.
                  </Link>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/realisaties/r_7.jpeg")} class="m-100" alt="" />
               </div>
            </div>

            <div className="row mb-5" id="s_sanitair">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Sanitair</h1>
                  <p>
                     Wij regelen uw sanitaire voorzieningen bij zowel nieuwbouw- als renovatieprojecten. Bij een nieuwbouw leggen wij alle leidingen en kleden wij de ruwbouw verder aan en installeren wij uw bad, douche, toilet, badkamermeubelen, waterkranen en lavabo’s.
                     <Link to="/realisaties" className="button accent-button accent-second">
                        Onze realisaties
                     </Link>
                  </p>

               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/realisaties/r_2.jpeg")} className="m-100" alt="Airconditioning / airco" />
               </div>
            </div>

            <div className="row mb-5" id="s_airco">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Airconditioning</h1>
                  <p>
                     Tegenwoordig is een airconditioning geen luxe meer maar comfort en soms wel een noodzaak. De installatie is vaak een klus die u het beste over kan laten aan een specialist. Wilt u in de woon- of slaapkamer een airco laten plaatsen maar weet  niet precies wat en hoe?
                      Schakel dan een locale specialist in voor de installatie en het plaatsen tegen een correcte prijs. Neem gerust vrijblijvend contact op met Palm CV.
                     <Link to="/contacteer-ons" className="button accent-button accent-second">
                        Contacteer ons
                     </Link>
                  </p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/airco.png")} className="m-100" alt="Airconditioning / airco" />
               </div>
            </div>

            <div className="row mb-5" id="s_ventilatie">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Ventilatie</h1>
                  <p>
                  Een goede ventilatie is een noodzaak in een goed geïsoleerde woning. Om goed te ventileren
volstaat het immers niet om enkel uw ramen en deuren open te zetten. Een goede ventilatie van uw
woning is enkel te bereiken met een ventilatiesysteem.  Door te ventileren blijft er steeds zuurstof
circuleren in uw woning en worden vocht en schadelijke stoffen afgevoerd. Sinds de EPB-wetgeving
is het bij wet verplicht om uw woning goed te ventileren. Palm CV kan u advies geven over de
verschillende systemen en garant staan voor een correcte plaatsing en inregeling.

                     <Link to="/contacteer-ons" className="button accent-button accent-second">
                        Contacteer ons
                     </Link>
                  </p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/realisaties/r_11.jpg")} className="m-100" alt="Airconditioning / airco" />
               </div>
            </div>

            <div className="row mb-5" id="s_zonnepanelen">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Zonne-energie</h1>
                  <p>
                  Milieuvriendelijke energie die uw kosten doen verminderen. Palm CV plaatst zonnepanelen en- of zonneboilers aangepast aan uw woning en specifieke gezinssituatie. Zonnepanelen kunnen op daken die Oost- Zuid- of West gericht zijn. Ze genereren ook op bewolkte dagen stroom. Bij eigen verbruik is de omzetting van gratis zonne-energie in stroom financieel bijzonder interessant. Investeer je in een nieuwe verwarmingsinstallatie kan je dit aanvullen met een zonneboiler. U profiteert van een lager energieverbruik en bijgevolg ook factuur.
                     <Link to="/contacteer-ons" className="button accent-button accent-second">
                        Contacteer ons
                     </Link>
                  </p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/realisaties/r_16.png")} className="m-100" alt="Zonnepanelen" />
               </div>
            </div>

            <div className="row mb-5" id="s_onderhoud">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto a-text">
                  <h1>Onderhoud</h1>
                  <p>
                     Wij onderhouden ketels van de meest courante fabrikanten. Wist je dat het onderhoud van een gasketel elke 2 jaar en mazoutketel jaarlijks wettelijk verplicht is? Onze erkende technieker reinigt de brander en stelt de ketel terug af. Je ontvangt van ons van deze metingen een wettelijk onderhoudsattest. Een gereinigde ketel bespaart op je energiefactuur, voorkom je storingen en verlengt de levensduur.
  Heb je onderhoud  nodig aan je airco of  heb je thuis een lekkende kraan, een probleem met je sanitaire installatie, loodgieterij of centrale verwarming?

                  </p>

                  <p>
                     Heb je onderhoud  nodig aan je airco of  heb je thuis een lekkende kraan, een probleem met je sanitaire installatie, loodgieterij of centrale verwarming?
                     <Link to="/contacteer-ons" className="button accent-button accent-second">
                        Contacteer ons
                     </Link>
                  </p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/IMG-20200812-WA0001.jpg")} className="m-100" alt="Onderhoud verwarming & onderhoud ketel" />
               </div>
            </div>

            <div className="row" id="s_renovatie">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Renovatie badkamer</h1>
                  <p>
                     Heeft u een lek of dringende herstelling?  Wenst u een kraan, douche of bad… te vervangen.  Daarvoor zoek  je mogelijk een vakman, Palm CV kan je helpen.
  Een badkamer is een veel  gebruikte ruimte in  woning waar onderhoud of renovatie toch nuttig kan zijn.
  Als uw badkamer aan renovatie toe is voorziet kan Palm CV helpen.  Van afbreken, leidingen leggen tot en met het plaatsen van nieuwe accessoires en mooie meubels.
  Zo kunt u er weer volop van genieten en comfortabel gebruik van maken.

                     <Link to="/realisaties" className="button accent-button accent-second">
                        Onze realisaties
                     </Link>
                  </p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 mb-5 a-img">
                  <img src={require("../images/renovatie.jpg")} class="m-100" alt="renovatie" />
               </div>
            </div>

            <div className="row mb-5" id="s_nieuwbouw">
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-text">
                  <h1>Nieuwbouwproject</h1>
                  <p>
                     Ook voor jullie nieuwbouw kan je op onze jarenlange ervaring beroep doen.
Wij voorzien sanitaire aan- en afvoerleidingen, ventilatie- airco- en verwarmingssysteem naar jou wens. We plaatsen ook alle sanitaire toestellen.
Maak een afspraak, aan de hand van jou plan worden alle mogelijkheden en wensen besproken. Je ontvangt dan onze gratis offerte op maat. <br /><br />

                     Droom je van een regendouche een moderne of klassiek interieur voor je badkamer? Worden jullie een jaartje ouder dan is een douche toegankelijker dan een bad. Wij luisteren naar jou wensen, bespreken de mogelijkheden en maken een aanbod op maat.
                     Voor sanitair en badkamers werken wij samen met de firma’s  Sanik, Desco, Schrauwen, en Alsan omdat wij hier de garantie hebben van kwaliteitsvolle producten. In de showroom van deze groothandelaars kan je je keuze maken, en wij zorgen dan voor de volledige installatie, zodat jullie zo snel mogelijk kunnen genieten van de nieuwe badkamer.


                  </p>

                  <p>
                     Neem gerust contact met ons op.  We maken een afspraak en aan de hand van jullie wensen maken we een voorstel.

                     <Link to="/contacteer-ons" className="button accent-button accent-second">
                        Contacteer ons
                     </Link>
                  </p>

               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 my-auto mb-5 a-img">
                  <img src={require("../images/nieuwbouw.jpg")} className="m-100" alt="Onderhoud verwarming & onderhoud ketel" />
               </div>
            </div>

         </div>

         <ContactBanner
            headTitle="Ons beter leren kennen?"
            title="Bekijk ons werk & realisaties."
            link="/realisaties"
            buttonText="Realisaties"
         />
         <RealisatiesBanner
            backgroundClass="accent-color bg-absolute no-bg-image"
            containerClass="padding-45"
         />
      </div>

   )
}

export default Over;