import React from 'react';

const PartnersBanner = () => {

   const Images = [
      {id:1, image: require("../images/partners/vaillant.png"), alt:"Vaillant", url:"https://www.vaillant.be/consumenten/"},
      {id:2, image: require("../images/partners/viessmann.png"), alt:"Viessmann", url:"https://www.viessmann.be/" },
      {id:3, image: require("../images/partners/henrad.png"), alt:"Henrad", url:"https://www.henrad.eu/henrad1/" },
      {id:4, image: require("../images/partners/zehnder.png"), alt:"Zehnder", url:"https://www.zehnder.be/" },
   ]

   return(
      <div className="container">
         <div className="row">
            {Images.map(({image, id, alt, url})=>(
               <a href={url} class="col-lg-3 col-md-6 col-sm-12 partner" key={id} target="_blank">
                  <img src={image} alt={alt}/>
               </a>
            ))}
         </div>
      </div>
   )
}

export default PartnersBanner;