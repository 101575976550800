import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const RealisatiesBanner = (props) => {

   const array = [
      require("../images/realisaties/r_3.jpeg"),
      require("../images/realisaties/r_4.jpeg")
   ]

   const realisaties = array.map((image, key) => {
      var imgUrl = image;
      var imageBg = {
         backgroundImage: 'url(' + imgUrl + ')',
         backgroundSize: "cover"
      };

      return <div className="col-md-4 realisatie-img" key={key}>
         <div className="realisatie-thumbnail" style={imageBg} ></div>
      </div>

   });

   const Button = () => {
      return props.buttonLink ? <div className="col-md-4 my-auto realisatie-btn"><Link className={"button accent-button dark " + props.buttonClass} to={props.buttonLink}>
         <img src={require("../images/icons/arrow.svg")} alt="" />
         {props.button}
         <div className="buttonSpan"></div>
      </Link></div> : <div className="col-md-2"></div>
   }

   return (

      <div className="container-fluid position-relative">
         <div className={props.backgroundClass}></div>
         <div className={"container " + props.containerClass} id="realisaties">
            <div className="row">
               <Button />
               {realisaties}
            </div>
         </div>
      </div>

   )
}

export default RealisatiesBanner;