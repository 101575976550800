import React, { useState } from 'react';
import ContactForm from '../components/ContactForm';
import RealisatiesBanner from '../components/realisatiesBanner';
import TextHeader from '../components/TextHeader';
import Hours from '../components/OpeningHours';
import Address from '../components/Address';
import Gegevens from '../components/Gegevens';
import { Helmet } from "react-helmet";
import PartnersBanner from '../components/PartnersBanner';
import Social from '../components/Social';

const Contact = () => {

   return (
      <div id="contact">

         <Helmet>
            <meta name="description" content="Contacteer Palm CV voor meer informatie of een vrijblijvende offerte omtrent uw centrale verwarming, onderhoud verwarmingsketel, airco, koeling, badkamerrenovatie of Ventilatie."></meta>
            <title>Palm CV - Contacteer ons</title>
         </Helmet>

         <TextHeader
            headTitle="Heeft u een vraag of wenst u een afsrpaak?"
            title="Contacteer ons"
            text="Heeft u nog vragen of wenst u een gratis prijsofferte?"
         />

         <div className="container-fluid">
            <div className="container skew-content">
               <div className="row">

                  <div className="col-xl-6 col-lg-12">
                     <div className="row mt-5">
                        <div className="col-xl-12 col-md-6 col-sm-12">
                           <ul className="list-unstyled">
                              <h3>Bedrijfsgegevens</h3>
                              <Address />
                              <li className="spacer"></li>
                              <Gegevens />
                              <li className="spacer"></li>
                              <Social />
                           </ul>
                        </div>
                        <div className="col-xl-12 col-md-6 col-sm-12">
                           <h3>Openingsuren</h3>
                           <Hours />
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-12">
                     <ContactForm />
                  </div>

               </div>

            </div>
         </div>

         <div className="map">
            <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4194.044684284053!2d5.056316680984421!3d51.24856524651947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b547f93ec045%3A0x9a09e712a4d205de!2sWenen%2045%2C%202470%20Retie!5e0!3m2!1sen!2sbe!4v1591623759254!5m2!1sen!2sbe"
               frameBorder="0"
               allowFullscreen=""
               aria-hidden="false"
               tabIndex="0"
               className="maps"
               title="palmcv"
            >
            </iframe>
         </div>

         <RealisatiesBanner
            button="Onze realisaties"
            buttonClass="dark with-arrow big"
            buttonLink="/realisaties"
            backgroundClass="accent-color bg-absolute de-skew"
            containerClass="de-skew padding-45"
         />

      </div>
   )
}

export default Contact;